@import url('https://fonts.googleapis.com/css2?family=Freeman&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.topics{
  background-color: black;
}
.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .circle-container img{
    margin-left: 10px;
    margin-top:10px;
  }
  .circle {
    width:350px;
    height: 250px;
    color:black;
    background-color:white;
    text-align: left;
    margin: 20px;
    opacity: 0;
    box-shadow: 10px 10px 10px rgb(255, 17, 0);
    transform: scale(0);
    transition: opacity 2s ease, transform 1s ease;
    position: relative;
    overflow: hidden;
  }
  
  .circle.active {
    opacity: 1;
    transform: scale(1);
  }
  .pictures{
    margin-top:20px;
    margin-left: 0;
    
  }
  #heading{
    width:50%;
    height:50px;
    border-radius:10px;
    margin-left: 25%; 
    margin-top: 0px;
    position: relative;
    top:15px;
   
  }
  #heading p{
    text-align: center;
  }

  .circle p{
    text-align:left;
    font-family: "Kanit", sans-serif;
    font-weight: 600;
    font-style:normal;
    padding-left: 10px;
  }

  .description{
    font-size:12px;
    font-weight:100;
    color:rgb(12, 12, 12);
  }
  
  .sliding-window {
    position: absolute;
    top: 0;
    left: -100%; /* Initial position, off-screen to the left */
    width: 100%;
    height: 100%;
   
    transition: left 0.5s; /* Smooth transition for sliding */
  }
  .circle:hover .sliding-window {
    left: 0; /* Slide in from left when .outer is hovered */
  }
  
  @media screen and (width<=1024px){
   .circle-container{
    display: inline;
   }
   .circle{
    width:90%;
    margin-left:5%;
   }
   #heading{
    width:90%;
    margin-left:5%;
    margin-bottom:40px;
   }
  }