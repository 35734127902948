@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

.navbar{
    background-color:black;
    height: 75px;
    display:flex;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    
}
.logopart {
    width:25%;
    display: flex;
    align-items:center ;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    color: white;
    letter-spacing:2px;
   
} 
.menuparts{
    display:flex;
    width:75%;
    margin-left: 50px;
    justify-items: center;
    justify-content: space-around;
    align-items: center;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    letter-spacing:2px;
}
li{
   list-style-type: None; 
}
.link{
    text-decoration: none;
    color:white;
}
.profile-pic{
    border-radius: 50%;
    height: 60px;
    width: 60px;
}
#loginbtn,#logoutbtn{
    border: none;
    background:none;
     align-items:center ;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    letter-spacing:2px;
}
.menu-icon {
  display: none;
  cursor: pointer;
}
.dropdown-menu {
  display: none;
  position: fixed; 
  width: 100vw; 
  height:250px; 
  background-color: black; 
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 999; 
}
@media screen and (width<=1024px) {
  .menuparts {
    display: none;
  }
  
  
  .menu-icon {
    display: block;
    cursor: pointer;
    margin-left:170px;
    margin-top: 18px;
   
  }

  .dropdown-menu {
    display: block;
  }

  .dropdown-menu li {
    padding: 10px 0;
  }
  #logoutbtn, #loginbtn{
    width:20%;
    margin-left:40%;
  }
  #closeBtn{
    width:50%;
    margin-left:25%;
  }
}