@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Whisper&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Whisper&family=Zeyada&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Whisper&family=Zeyada&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Dancing+Script:wght@400..700&family=Whisper&family=Zeyada&display=swap');
.header{
       display: flex;
       background-color:black;  
       height: 650px;
}
.aboutus{
    width: 50%;
   
}
.abtusprt1{
    display:flex;
    position: relative;
    top:10px;
    left:200px;
   
    
}
.abtheading{
    position:relative;
    right:105px;
    top:30px;
    color:white;
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style:normal;
    font-size:50px;
}
.aboutus p{
    color:white;
    text-align:justify;
    padding-left:10px;
    padding-right:10px;
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
    
    
}
#pic1{
    position: relative;
    top:100px;
    width: 50%;   
   
}
#pic1 img{
    max-width: 100%; 
    box-shadow: 10px 10px 10px rgb(255, 17, 0);
}
#whyus{
    position: relative;
    right:100px;
    top:30px;
    font-size: 17px;
    
    
}


.cardcontainer{
    height:200px;
    display: flex;
    justify-content: space-around;
    position: relative;
    bottom: 30px;
   
}
.card{
    width:15%;
    background-color: white ;
    height: 200px;
    box-shadow: 15px 15px 15px rgb(255, 17, 0);
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease, transform 1s ease;
    
}
.card.active {
    opacity: 1;
    transform: scale(1);
  }
.cardimg{
    position: relative;
    top:10px;
}
.card p{
    text-align: center;
    font-family: "Kanit", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
}

.sliding-window {
    position: absolute;
    top: 0;
    left: -100%; 
    width: 100%;
    height: 100%;
    
    transition: left 0.8s; 
  }
  .card:hover .sliding-window {
    left: 0; 
  }
.mentor{
    background-color:black;
    color:white;
}
.mentorheading{
  margin-top: 20px; 
  border: 2px solid black;  
}
#meetmentor{
    font-size: 30px;
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style: normal;
    color:rgb(255, 17, 0); 
}

#alex{
    height:300px;
    display: flex;
}
#alexdp{
    position: relative;
    bottom:210px;
}
.disc{
    font-weight: 500;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
}
  

#name1,#name2{
    text-align: center;
    font-size: 30px;
}

#ashar{
    display: flex;
    
}
#asharpic{
    display:flex;
    width: 20%;
    margin-right: 20px;
    position: relative;
    top:50px;
}

@media screen and (width <=1024px) {
    .header{
        display: inline;
        background-color: black;
        
    }
    #pic1{
       
        width: 100%;
        position:static;
       
        
    }
    .aboutus{
        width:100%;
        background-color: black;
       
    }
    .abtusprt1{
        display:flex;
        position: static; 
 
    }

    .abtheading{
        position:relative;
        top:70px;
        color:white;
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size:40px;
    }
    #whyus{
        position:static;
    }
    #pic2{
       display:none;
    }
    .cardcontainer{
        position: static;
        display: inline;
        background-color: black;
    }
    .card{
        width:80%;
        margin-left:10%;
        margin-bottom: 30px;
    }

    #meetmentor{
        font-size: 25px;
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        font-style: normal;
        color:rgb(255, 17, 0); 
    }
   #alex{
    display: inline;
   }
   .frame{
    display: none;
   }
   #alexdp{
    position: relative;
    top:5px;
    box-shadow: 15px 15px 15px rgb(255, 17, 0); 
    border-radius:10px;
   }
   #ashar{
    display:flex;
    flex-direction: column;
   }
   #profileBox2{
    order:1;
   }
   #ashardiscription{
    order:2;
   }
}