@import url('https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freeman&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.footer{
    background: linear-gradient(to top,rgb(255, 17, 0), #461d1d);
    display: flex;
    justify-content: space-around;
    height:470px;
    color:white;
}
.logo{
    width:25%;
  
}
.contact{
    width:30%;
    margin-left:50px;
    margin-top:80px;
     display: flex;
    flex-direction: column;
   
    
}
#info{
    text-align: center;
  
    margin-left:3px;
}
.links{
    width:15%;
    margin-top: 80px; 
}
.link p:hover{
    color:black;
    font-weight: 800;
}

.photo{
    width:30%;
    
}
.logo p,.contact p,.link p,.photo p{
    font-family: "Carrois Gothic SC", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
} 

#buttons{
    display: flex;
    justify-content:space-evenly;

}
a{
    color: rgb(0, 0, 0);
}
a:hover{
    color:white;
}
.address p{
    font-size:15px;
    text-align: left;
}

.contsub{
    display: flex;
    
}
.link{
    display: flex;
    flex-direction: column;   
}
.link p{
    text-align:left;
    
}
.pics{
    border-radius: 50%;
}


.photo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-container {
    position: relative;
    margin-bottom: 10px;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(rgba(3, 0, 0, 0.459), rgba(0,0,0,0.5));
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-container:hover .overlay {
    opacity: 1;
  }
  .developerinfo{
    background-color:white;
    height: 30px;
    
  }
  .developerinfo a:hover{
    color: red;
   }
  @media screen and (width<=1024px) {
    .footer{
      display: inline;
    }
    .logo{
        width:100%;
      background: linear-gradient(to top,rgb(255, 17, 0), #461d1d);
        
    }
    .contact{
       width:100%;
       background-color: black;
       margin: 0 0 0 0;
    
    }
   .links{
    width:100%;
     background: linear-gradient(to top,rgb(255, 17, 0), #461d1d);
     margin-top:0px;
   }
   .link p{
text-align: center;
   }
   .photo{
    width: 100%;
    background-color: black;
    margin: 0 0 0 0;
   }
   .developerinfo{
    height: 40px;
   }
   
  }