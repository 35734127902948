.courseContainer{
    padding-top: 30px;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
}
#enroll{
    
    width:90%;
    background-color: black;
    color: white;
    margin-left:5%;
    margin-bottom: 30px;
    border-radius:10px;
    box-shadow: 10px 10px 10px rgb(255, 17, 0);

    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style:normal;
    font-size: 17px;
}


@media screen and (width<=1024px){
    .courseContainer{
        display: inline;
    }
    #enroll{
        height:30px;  
    }
}
