.PhoneForm{
    height: 200px;
    background-color: black;
    color: white;
    width: 50%;
    margin-left: 25%;
    margin-bottom:15px;
    position: relative;
    bottom: 10px;
    box-shadow: 10px 10px 10px rgb(255, 17, 0);
    border-radius: 10px;
}
input{
    width:270px;
    height:30px;
    border-radius: 10px;
    padding: 2px 0px 2px 15px;
    box-shadow: 10px 10px 10px rgb(255, 17, 0);
    border:none;
}
.PhoneForm button {
    margin-top:30px;
    width:100px;
    height: 30px;
    border-radius: 8px;
    border: none;
    background-color:red;
    color: white;
    box-shadow: 5px 5px 5px rgb(255, 255, 255);
}
.PhoneForm button:hover{
    background-color:white;
    color:black;
    box-shadow: 5px 5px 5px rgb(255, 17, 0); ;
}
@media screen and (width<=1024px) {
    .PhoneForm{
        width: 90%;
        margin-left: 4%;
    }
}