
@import url('https://fonts.googleapis.com/css2?family=Freeman&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.banner{
    width:100%;
    height:400px;
    background: linear-gradient(to right,rgb(255, 17, 0), #000000f3), url('./images/backgroubd.jpg'); 
    display: flex;
    
}
.slogan{
    width:40%;
    margin-left:15px;
    margin-top:110px;
    text-align: left;
   
}
#slg1{
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 30px;
  color: white;
 
    
}

#slg3{
  position: relative;
  bottom:5px;
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
    
}

.pictures{
    width:60%;
}



.waviy {
    position: relative;
 
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,.2));
    font-size:30px;
  }
  .waviy span {
    font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 35px;
    position: relative;
    display: inline-block;
    color: black;
    text-transform: uppercase;
    animation: waviy 2s infinite;
    animation-delay: calc(.1s * var(--i));
    
  }
  @keyframes waviy {
    0%,40%,100% {
      transform: translateY(0)
    }
    20% {
      transform: translateY(-20px)
    }
}


#slg3 {
    position: relative;
    color: hsl(0, 0%, 100%);
    letter-spacing:1px;
    overflow: hidden;
    border-right: 2px solid hsl(0, 0%, 80%);
    white-space: nowrap;
    animation: typewriter 3s steps(40) 1s 1 normal both,
      blinkTextCursor 500ms infinite;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
    to {
      width:500px;
    }
  }
  @keyframes blinkTextCursor {
    from {
      border-right-color: hsl(0, 0%, 80%);
    }
    to {
      border-right-color: transparent;
    }
  }

  @media screen and (width<=1024px){
    .banner{
      display:inline;
     
    }
    .pictures{
      width: 100%;
      background-color: black;
    }
    .slogan{
     width:100%;
     margin:0 0 0 0;
     background-color:rgb(255, 17, 0);
     height: 167px;
    }
    .picures {
      text-align: center;
      background-color: black; 
      max-width: 100%; 
    }
    .picures img {
      max-width: 100%; 
      height: auto; 
    }
    #slg1{
      font-family: "Kanit", sans-serif;
      font-weight: 800;
      font-style: normal;
      font-size: 25px;
      color: white;   
    }
    
    
  }