@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.glassButton {
  position: relative;
  padding: 10px 36px;
  color: white;
  font-family: "Roboto";
  font-weight: 600;
  height:20px;
  background-color: rgb(9, 9, 9);
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  box-shadow: 10px 10px 10px rgb(255, 17, 0);
  border-radius:5px;
  margin-left: 2px;
}

.glassButton:before {
  content: "";
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
}

.glassButton:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  transition: 0.5s;
}

.glassButton:hover:after {
  left: 100%;
}
