@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Roboto+Slab:wght@100..900&family=Teko:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Roboto+Slab:wght@100..900&family=Teko:wght@300..700&display=swap");
.outer {
  background-color: black;
  width: 430px;
  height: 335px;
  border-radius: 5%;
  color: white;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  box-shadow: 10px 10px 10px rgb(255, 17, 0);
}
#courseImg {
  margin: 0 0 0 0;
  border-radius: 5%;
  width: 100%;
}
#priceSect {
  display: flex;
  justify-content: space-around;
}
#priceSect p {
  font-family: "Carrois Gothic SC", sans-serif;
  font-weight: 400;
  font-style: normal;
}
#CourseTitle {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  padding-left: 5px;
}

.sliding-window {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.664);
  transition: left 0.5s;
}

.outer:hover .sliding-window {
  left: 0;
}

@media screen and (width<=1024px) {
  .outer {
    width: 90%;
    height: 310px;
    margin-left: 5%;
  }
  #courseImg {
    width: 100%;
  }
}
